import axios from 'axios'
import axiosIns from '@axios'
const state = {
    members: [],
};
const getters = {
    allMemebers: (state) => state.members
};
const mutations = {
    pushData(state, data) {
        state.members = data;
    }

};
const actions = {
    async getDatas({ commit }) {
        // const response = await axios.get('http://127.0.0.1:8000/dtm/member/');
        if (state.members == "") {
            
            await axiosIns.get('/dtm/member/').then((res) => {
                console.log(res.data)
                commit('pushData', res.data)
                }).catch(err => console.log(err))
        }
    }

}
export default {
    state,
    getters,
    mutations,
    actions
};
