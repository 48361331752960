export default [
  {
    path: '/member/all',
    name: 'member',
    component: () => import('@/views/member/Member.vue'),
    meta: {
      pageTitle: 'DTM List',
        breadcrumb: [
          {
            text: 'DTM List',
            active: true,
          },
        ],
    },
  },

  // *===============================================---*
  // *--------- EMAIL & IT'S FILTERS N LABELS -------------------------------*
  // *===============================================---*
  {
    path: '/user/debit',
    name: 'user-debit',
    component: () => import('../../views/debit/debit.vue'),
    meta: {
      pageTitle: 'Debit',
        breadcrumb: [
          {
            text: 'Debit Account',
            active: true,
          },
        ],
    },
  },
  {
    path: '/user/authpay',
    name: 'user-authpay',
    component: () => import('@/views/authpay/authPay.vue'),
    meta: {
      pageTitle: 'Payment Verify',
        breadcrumb: [
          {
            text: 'Payment Verify',
            active: true,
          },
        ],
    },
  },
  {
    path: '/sundy/fund',
    name: 'sundy-fund',
    component: () => import('@/views/SundyPay.vue'),
    meta: {
      pageTitle: 'Sundy Fund',
        breadcrumb: [
          {
            text: 'Sundy Fund',
            active: true,
          },
        ],
    },
  },
  {
    path: '/all/reports',
    name: 'all-reports',
    component: () => import('@/views/report'),
    meta: {
      pageTitle: 'Reports',
        breadcrumb: [
          {
            text: 'Report',
            active: true,
          },
        ],
    },
  },
  {
    path: '/food/sponsor',
    name: 'food-sponsor',
    component: () => import('@/views/food/foodsponsor.vue'),
    meta: {
      pageTitle: 'Food Sponsor',
        breadcrumb: [
          {
            text: 'Food Sponsor List',
            active: true,
          },
        ],
    },
  },
  {
    path: '/food/management',
    name: 'food-management',
    component: () => import('@/views/food/foodmanagement.vue'),
    meta: {
      pageTitle: 'Food Management',
        breadcrumb: [
          {
            text: 'Food Management List',
            active: true,
          },
        ],
    },
  },
//   {
//     path: '/apps/email/:folder',
//     name: 'apps-email-folder',
//     component: () => import('@/views/apps/email/Email.vue'),
//     meta: {
//       contentRenderer: 'sidebar-left',
//       contentClass: 'email-application',
//       navActiveLink: 'apps-email',
//     },
//     beforeEnter(to, _, next) {
//       if (['sent', 'draft', 'starred', 'spam', 'trash'].includes(to.params.folder)) next()
//       else next({ name: 'error-404' })
//     },
//   },
//   {
//     path: '/apps/email/label/:label',
//     name: 'apps-email-label',
//     component: () => import('@/views/apps/email/Email.vue'),
//     meta: {
//       contentRenderer: 'sidebar-left',
//       contentClass: 'email-application',
//       navActiveLink: 'apps-email',
//     },
//     beforeEnter(to, _, next) {
//       if (['personal', 'company', 'important', 'private'].includes(to.params.label)) next()
//       else next({ name: 'error-404' })
//     },
//   },

//   // *===============================================---*
//   // *--------- TODO & IT'S FILTERS N TAGS ---------------------------------------*
//   // *===============================================---*
//   {
//     path: '/apps/todo',
//     name: 'apps-todo',
//     component: () => import('@/views/apps/todo/Todo.vue'),
//     meta: {
//       contentRenderer: 'sidebar-left',
//       contentClass: 'todo-application',
//     },
//   },
//   {
//     path: '/apps/todo/:filter',
//     name: 'apps-todo-filter',
//     component: () => import('@/views/apps/todo/Todo.vue'),
//     meta: {
//       contentRenderer: 'sidebar-left',
//       contentClass: 'todo-application',
//       navActiveLink: 'apps-todo',
//     },
//     beforeEnter(to, _, next) {
//       if (['important', 'completed', 'deleted'].includes(to.params.filter)) next()
//       else next({ name: 'error-404' })
//     },
//   },
//   {
//     path: '/apps/todo/tag/:tag',
//     name: 'apps-todo-tag',
//     component: () => import('@/views/apps/todo/Todo.vue'),
//     meta: {
//       contentRenderer: 'sidebar-left',
//       contentClass: 'todo-application',
//       navActiveLink: 'apps-todo',
//     },
//     beforeEnter(to, _, next) {
//       if (['team', 'low', 'medium', 'high', 'update'].includes(to.params.tag)) next()
//       else next({ name: 'error-404' })
//     },
//   },

//   // *===============================================---*
//   // *--------- CHAT  ---------------------------------------*
//   // *===============================================---*
//   {
//     path: '/apps/chat',
//     name: 'apps-chat',
//     component: () => import('@/views/apps/chat/Chat.vue'),
//     meta: {
//       contentRenderer: 'sidebar-left',
//       contentClass: 'chat-application',
//     },
//   },

//   // *===============================================---*
//   // *--------- ECOMMERCE  ---------------------------------------*
//   // *===============================================---*
//   {
//     path: '/apps/e-commerce/shop',
//     name: 'apps-e-commerce-shop',
//     component: () => import('@/views/apps/e-commerce/e-commerce-shop/ECommerceShop.vue'),
//     meta: {
//       contentRenderer: 'sidebar-left-detached',
//       contentClass: 'ecommerce-application',
//       pageTitle: 'Shop',
//       breadcrumb: [
//         {
//           text: 'ECommerce',
//         },
//         {
//           text: 'Shop',
//           active: true,
//         },
//       ],
//     },
//   },
//   {
//     path: '/apps/e-commerce/wishlist',
//     name: 'apps-e-commerce-wishlist',
//     component: () => import('@/views/apps/e-commerce/e-commerce-wishlist/ECommerceWishlist.vue'),
//     meta: {
//       pageTitle: 'Wishlist',
//       contentClass: 'ecommerce-application',
//       breadcrumb: [
//         {
//           text: 'ECommerce',
//         },
//         {
//           text: 'Wishlist',
//           active: true,
//         },
//       ],
//     },
//   },
//   {
//     path: '/apps/e-commerce/checkout',
//     name: 'apps-e-commerce-checkout',
//     component: () => import('@/views/apps/e-commerce/e-commerce-checkout/ECommerceCheckout.vue'),
//     meta: {
//       pageTitle: 'Checkout',
//       contentClass: 'ecommerce-application',
//       breadcrumb: [
//         {
//           text: 'ECommerce',
//         },
//         {
//           text: 'Checkout',
//           active: true,
//         },
//       ],
//     },
//   },
//   {
//     path: '/apps/e-commerce/:slug',
//     name: 'apps-e-commerce-product-details',
//     component: () => import('@/views/apps/e-commerce/e-commerce-product-details/ECommerceProductDetails.vue'),
//     meta: {
//       pageTitle: 'Product Details',
//       contentClass: 'ecommerce-application',
//       breadcrumb: [
//         {
//           text: 'ECommerce',
//         },
//         {
//           text: 'Shop',
//           active: true,
//         },
//         {
//           text: 'Product Details',
//           active: true,
//         },
//       ],
//     },
//   },

//   // *===============================================---*
//   // *--------- USER ---- ---------------------------------------*
//   // *===============================================---*
//   {
//     path: '/apps/users/list',
//     name: 'apps-users-list',
//     component: () => import('@/views/apps/user/users-list/UsersList.vue'),
//   },
//   {
//     path: '/apps/users/view/:id',
//     name: 'apps-users-view',
//     component: () => import('@/views/apps/user/users-view/UsersView.vue'),
//   },
//   {
//     path: '/apps/users/edit/:id',
//     name: 'apps-users-edit',
//     component: () => import('@/views/apps/user/users-edit/UsersEdit.vue'),
//   },

//   // Invoice
//   {
//     path: '/apps/invoice/list',
//     name: 'apps-invoice-list',
//     component: () => import('@/views/apps/invoice/invoice-list/InvoiceList.vue'),
//   },
//   {
//     path: '/apps/invoice/preview/:id',
//     name: 'apps-invoice-preview',
//     component: () => import('@/views/apps/invoice/invoice-preview/InvoicePreview.vue'),
//   },
//   {
//     path: '/apps/invoice/add/',
//     name: 'apps-invoice-add',
//     component: () => import('@/views/apps/invoice/invoice-add/InvoiceAdd.vue'),
//   },
//   {
//     path: '/apps/invoice/edit/:id',
//     name: 'apps-invoice-edit',
//     component: () => import('@/views/apps/invoice/invoice-edit/InvoiceEdit.vue'),
//   },
]
