export default [
  {
    path: '/cash/reg/fee',
    name: 'cash-reg-fee',
    component: () => import('@/views/extensions/CashPay.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      pageTitle: 'Cash Payment',
        breadcrumb: [
          {
            text: 'Payment',
            active: true,
          },
        ],
    },
  },
  {
    path: '/acl/addfood',
    name: 'acl-addfood',
    component: () => import('@/views/food/addfooddistribution.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      pageTitle: 'Add Food Distribution',
        breadcrumb: [
          {
            text: 'Distribution Area',
            active: true,
          },
        ],
    },
  },
  {
    path: '/acl/profile',
    name: 'acl-profile',
    component: () => import('@/views/profile/index.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
      pageTitle: 'Account Profile',
        breadcrumb: [
          {
            text: 'Profile',
            active: true,
          },
        ],
    },
  },
]
